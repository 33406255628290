<template>
  <b-card title="Add Country">
      <validation-observer ref="simpleRules">
          <b-form @submit.prevent>
              <b-row>
                  <!-- Country -->
                  <b-col cols="12">
                      <b-form-group label="Country" label-for="Country">
                          <validation-provider name="Country" rules="required|max:100" #default="{ errors }">
                              <b-input-group class="input-group-merge"
                                  :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                  <b-input-group-prepend is-text>
                                      <feather-icon icon="UserIcon" />
                                  </b-input-group-prepend>

                                  <b-form-input id="country" v-model="item.country"
                                      :state="errors.length > 0 ? false : null" placeholder="Country" />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </b-form-group>
                  </b-col>

                  <!-- Slug -->
                  <b-col cols="12">
                      <b-form-group label="Slug" label-for="Slug">
                          <validation-provider name="Slug" rules="required" #default="{ errors }">
                              <b-input-group class="input-group-merge"
                                  :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                  <b-input-group-prepend is-text>
                                      <feather-icon icon="UserIcon" />
                                  </b-input-group-prepend>

                                  <b-form-input id="slug" v-model="item.slug"
                                      :state="errors.length > 0 ? false : null" placeholder="Slug" readonly/>
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </b-form-group>
                  </b-col>

                  <!-- Profile Preview -->

                  <b-col cols="12">
                      <b-img thumbnail fluid :src="previewImg" :class="previewImg.length <= 0 ? 'd-none' : null"
                          alt="Image"></b-img>
                  </b-col>

                  <!-- Profile Image -->
                  <b-col cols="12">
                      <b-form-group label="Profile Image" label-for="profile">
                          <validation-provider name="Profile Image" rules="required|max:20" #default="{ errors }">
                              <b-input-group class="input-group-merge"
                                  :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                  <b-input-group-prepend is-text>
                                      <feather-icon icon="ImageIcon" />
                                  </b-input-group-prepend>
                                  <b-form-file id="profile" v-model="profileImg" :state="errors.length > 0 ? false : null"
                                      placeholder="Choose a file or drop it here..."
                                      drop-placeholder="Drop file here..." />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </b-form-group>
                  </b-col>

                  <b-col cols="12">
                      <b-form-group label="Status" label-for="Status">
                          <validation-provider name="Status" rules="required" #default="{ errors }">
                              <b-input-group class="input-group-merge"
                                  :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                  <b-input-group-prepend is-text>
                                      <feather-icon icon="MapPinIcon" />
                                  </b-input-group-prepend>
                                  <b-form-select id="publish" v-model="item.status" :options="options" :state="errors.length > 0 ? false : null">
                                  </b-form-select>
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </b-form-group>
                  </b-col>

                  <!-- submit and reset -->
                  <b-col>
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit"
                          @click.prevent="validationForm" variant="primary" class="mr-1">
                          Submit
                      </b-button>
                      <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                          @click="reset">
                          Reset
                      </b-button>
                  </b-col>
              </b-row>
          </b-form>
      </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormText,
  BFormDatalist,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
  BFormFile,
  BFormSelect,
  BFormTextarea,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
//Vee-Validate 
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  required
} from '@validations'
export default {
  components: {
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BInputGroup,
      BInputGroupPrepend,
      BFormInput,
      BFormCheckbox,
      BForm,
      BFormText,
      BButton,
      BFormDatalist,
      BFormInvalidFeedback,
      BFormValidFeedback,
      BFormFile,
      BFormSelect,
      BFormTextarea,
      BImg,
      ValidationProvider,
      ValidationObserver,
      required,
  },
  directives: {
      Ripple,
  },
  data() {
      return {
          item: {},
          name: '',
          country:'',
          slug:'',
          previewImg: '',
          profileImg: '',
          selectedoptions: null,
          selectedCountry: null,
          options: [
              {'value': null, text: 'Please select Publish / Unpblish'},
              {'value': 0, 'text': 'Unpublished'},
              {'value': 1, 'text': 'published'}
          ],
          countryOptions: [
              { value: null, text: 'Please select a country' },
          ]
      }
  },
  created() {
      this.getCountries();
      this.getItem()
  },
  computed: {
      validation() {
          return this.name.length > 4 && this.name.length < 13
      },
      slug: function () {
        return this.slugify(this.country)
      }
  },
  mounted() {
  this.createMap();
},
  methods: {
      reset() {
          this.name = '',
          this.country ='',
          this.slug ='',
          this.previewImg = '',
          this.profileImg = '',
          this.selectedoptions = null,
          this.selectedCountry = null
      },
      getCountries() {
          this.countries = [];
          db.collection("countries")
              .get()
              .then((querySnapshot) => {
                  //console.log('hhhh1');
                  querySnapshot.forEach((doc) => {
                      this.countryOptions.push({
                          value: doc.id,
                          text: doc.data().country,
                          slug: doc.data().slug,
                      });
                      console.log(doc.id, " => ", doc.data());
                  });
              })
              .catch((error) => {
                  console.log("Error getting documents: ", error);
              });
      },
      getItem() {
            store.dispatch('app/commitActivateLoader');
            var id = this.$route.params.id;

            db.collection('countries').doc(id).get().then((doc) => {
                console.log(doc.data());
                this.item = doc.data();
            })
            store.dispatch('app/commitDeactivateLoader');
        },
      validationForm() {
          this.$refs.simpleRules.validate().then(success => {
              if (success) {
                  // eslint-disable-next-line
                  // alert('form submitted!')
                  this.submit();
              }
          })
      },

      submit() {
          console.log(this.selectedoptions);
          store.dispatch('app/commitActivateLoader');
           var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
                          db.collection("countries")
                          .add({
                              country: this.country,
                              slug: this.slug,
                              status:parseInt(this.selectedoptions),
                              created: date,
                              modified: date,
                          })
                              .then((doc) => {
                                  console.log("Added Successfully!");
                                  const filename = this.profileImg.name;
                                  const ext = filename.slice(filename.lastIndexOf('.'));
                                  const storageRef = storage.ref();
                                  const fileRef = storageRef.child('countries/' + doc.id + "." + ext);
                                  fileRef.put(this.profileImg).then(result => {
                                      fileRef.getDownloadURL().then(imageUrl => {
                                          console.log(imageUrl);
                                          db.collection("countries").doc(doc.id).update({ image: imageUrl })
                                          store.dispatch('app/commitDeactivateLoader');
                                          this.$router.push({ name: 'country-index' });
                                          this.showNotification('Country Added Successfully!', 'AlignJustifyIcon', 'success');
                                      }).catch((error) => {
                                          console.error("Error writing document: ", error);
                                          this.showNotification('Error! Request Failed!', 'AlignJustifyIcon', 'danger');
                                      });
                                  });
                              })
                          // var id = localStorage.getItem("userSessionId");
                          // console.log('rrr ' + id);
      },
      showNotification(title, icon, variant) {
          this.$toast({
              component: ToastificationContent,
              props: {
                  title,
                  icon,
                  variant,
              },
          })
      },
  },
}
</script>